// @flow

import { NoIndexMetaTag, PageContent, SEO, SocialTags } from "@components";
import { Analytics } from "@containers";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { ContentModelMapper } from "@utils";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { CategoryBarContext } from "@utils/Category/CategoryBarContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { contentfulTypenames, determineStyle } from "../utils";
import {
    LEAD_SCORING_DEFAULT_VALUES,
    TWITTER_HANDLE,
} from "../utils/Constants";
import { loadLocalStorageId } from "../utils/LocalStorage";
import { getHeaderData } from "../utils/PageHeader";

const PageArticleTemplate = ({ data, query, location }: *) => {
    const {
        page,
        channel,
        market,
        navigation,
        footer,
        blogHome,
        site: {
            siteMetadata: { siteUrl },
        },
    } = data;

    const navLogo = navigation?.logo;

    const navData = determineStyle("Default", page.navigation || navigation);

    return (
        <CategoryBarContext.Provider value={true}>
            <LocaleContext.Provider
                value={{
                    language: page.node_locale,
                    market,
                }}
            >
                <StorageContext.Provider
                    value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
                >
                    <Analytics.PageWrapper
                        key={page.id}
                        entry={page.id}
                        type={PAGE_TYPES.ARTICLE}
                        title={page.title}
                        language={page.node_locale}
                        urlParameters={query}
                        slug={page.slug}
                        segments={page.segments}
                        dossier={location.state?.dossier}
                        edition={location.state?.edition}
                        seoKeywords={page.seoKeywords}
                        leadScoringValue={
                            page.leadScoringValue ||
                            LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.ARTICLE]
                        }
                        tags={page.metadata.tags}
                    >
                        <SEO
                            title={page.title}
                            translations={page.translations}
                            publishedMarkets={page.markets}
                            metaDescription={page.metaDescription}
                            siteUrl={siteUrl}
                            currentPath={location.pathname}
                            page={{
                                ...page,
                                siteUrl,
                                navLogo,
                                blogHome,
                            }}
                            market={market}
                        />
                        <SocialTags
                            title={page.entry?.title}
                            description={
                                page.entry?.introductionCopy?.raw
                                    ? documentToPlainTextString(
                                          JSON.parse(
                                              page.entry.introductionCopy.raw,
                                          ),
                                      )
                                    : page.entry?.title
                            }
                            asset={page.entry?.socialAsset}
                            siteUrl={siteUrl}
                            twitterHandle={TWITTER_HANDLE}
                        />
                        <NoIndexMetaTag page={page} channel={channel} />
                        <PageContent
                            navigation={navData}
                            footer={page.footer || footer}
                            translations={page.translations}
                            contentAboveEntry={page.contentAboveEntry}
                            header={
                                page.pageHeader &&
                                ContentModelMapper.map({
                                    ...getHeaderData(page, market),
                                    backgroundCard: true,
                                    parentContainer:
                                        contentfulTypenames.pageTypeArticle,
                                })
                            }
                            entry={{ ...page, blogHome }}
                            contentBelowEntry={page.contentBelowEntry}
                            publishedMarkets={page.markets}
                            offer={page.offer}
                            slug={page.slug}
                            enableScrollTop={page.enableScrollTop}
                            disclaimer={page.disclaimer}
                            hideCategoryBar={false}
                            hideCategoryBarMobile={false}
                            disablePip={page.disablePip}
                        />
                    </Analytics.PageWrapper>
                </StorageContext.Provider>
            </LocaleContext.Provider>
        </CategoryBarContext.Provider>
    );
};

export default withURLParameters(PageArticleTemplate);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPage(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            ...PageGeneral
            allDossiersSlug
            relevantCampaign(language: $language, marketCode: $marketCode) {
                ... on ContentfulCampaign {
                    name
                    contentful_id
                    defaultContent {
                        ...CallToAction
                        ...TextBlock
                    }
                    defaultImage {
                        id
                        contentful_id
                        alternativeText
                        caption
                        focalPoint
                        image {
                            fields {
                                ensured {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        placeholder: BLURRED
                                        formats: [AUTO]
                                        quality: 85
                                        breakpoints: [576, 768, 1024]
                                    )
                                    title
                                    file {
                                        url
                                    }
                                    description
                                }
                            }
                        }
                    }
                    overwriteArticleNewsletterForm
                    visionaryBanner {
                        ...VisionaryBanner
                    }
                }
            }
            initialArticles(
                language: $language
                categoryId: $categoryId
                excludeId: $id
                marketCode: $marketCode
            )
            defaultForm(language: $language, marketCode: $marketCode) {
                ...Form
            }
            breadcrumb
            entry {
                ... on ContentfulArticle {
                    __typename
                    id
                    title
                    publishDate
                    node_locale
                    categories {
                        ...MainCategory
                        ...SubCategory
                    }
                    introductionCopy {
                        raw
                        references {
                            __typename
                            ... on ContentfulPage {
                                contentful_id
                                slug
                                channels {
                                    channel
                                }
                            }
                            ... on ContentfulPageLanding {
                                contentful_id
                                slug
                                channels {
                                    channel
                                }
                            }
                            ... on ContentfulGlossaryItem {
                                contentful_id
                                image {
                                    fields {
                                        ensured {
                                            gatsbyImageData(
                                                formats: [WEBP]
                                                quality: 85
                                                height: 397
                                                width: 276
                                                aspectRatio: 1.5
                                            )
                                            title
                                            file {
                                                url
                                            }
                                        }
                                    }
                                }
                                imagePosition
                                title
                                description {
                                    raw
                                    references {
                                        __typename
                                        ... on ContentfulPage {
                                            contentful_id
                                            slug
                                            channels {
                                                channel
                                            }
                                        }
                                        ... on ContentfulPageLanding {
                                            contentful_id
                                            slug
                                            channels {
                                                channel
                                            }
                                        }
                                    }
                                }
                            }
                            ... on ContentfulCallToAction {
                                contentful_id
                                ...CallToAction
                            }
                        }
                    }
                    experts {
                        firstName
                        lastName
                        responsibilities {
                            functionTitle
                        }
                        pageExpert {
                            slug
                            markets {
                                code
                            }
                        }
                    }
                    localizedExperts {
                        firstName
                        lastName
                        responsibilities {
                            functionTitle
                        }
                        pageExpert {
                            slug
                            markets {
                                code
                            }
                        }
                    }
                    socialAsset: mainAsset {
                        ... on ContentfulImageBlock {
                            alternativeText
                            image {
                                fields {
                                    ensured {
                                        title
                                        file {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                        ... on ContentfulVideoBlock {
                            videoUrl
                            localizedVideoUrl
                        }
                    }
                    mainAsset {
                        ... on ContentfulImageBlock {
                            id
                            alternativeText
                            caption
                            focalPoint
                            image {
                                fields {
                                    ensured {
                                        gatsbyImageData(
                                            layout: FULL_WIDTH
                                            placeholder: BLURRED
                                            formats: [WEBP]
                                            quality: 85
                                            breakpoints: [
                                                576
                                                768
                                                1024
                                                1366
                                                1920
                                            ]
                                        )
                                        title
                                        file {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                        ...VideoBlock
                    }
                    content {
                        ...DisplayExpandableCard
                        ...Content
                        ...DisplaySection
                        ...ContentSelector
                        ... on ContentfulDisplayCollection {
                            ...Content
                        }
                        ...VariantContainer
                    }
                    readingTime
                    context
                    dossiers(marketCode: $marketCode) {
                        title
                        slug
                        node_locale
                        entry {
                            ... on ContentfulDossier {
                                ...DossierGeneral
                                editions: dossierEditions(
                                    marketCode: $marketCode
                                ) {
                                    __typename
                                    articles: marketSpecificArticles(
                                        marketCode: $marketCode
                                        language: $language
                                        excludeId: $id
                                    )
                                    experts(
                                        language: $language
                                        marketCode: $marketCode
                                    )
                                }
                            }
                        }
                    }
                    displayAutomaticCTA
                }
            }
            contentAboveEntry {
                ...Content
                ...ContentSelector
                ...VariantContainer
            }
            contentBelowEntry {
                ...Content
                ...ContentSelector
            }
            disclaimer {
                ...Disclaimer
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
        blogHome: contentfulPageLanding(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            blogHomepage: { eq: true }
        ) {
            slug
            title
            breadcrumb
        }
    }
`;
